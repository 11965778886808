.connect {
    h1.yellow {
        background: $grey2;
        margin: 0;
        padding: 0.15em 68px 0.15em;
        p {
            @include font(18);
            color: white;
            margin: 1.6em 0;
            font-size: 24px;
        }
    }
    @media(max-width:485px) {
        h1.yellow {
            font-size: 30px;
            padding: 2.4em 36px 1em;
            p {
                margin: 0;
                font-size: 14px;
            }
        }   
    }
    #team-listing {
        column-count: 2;
        padding-left: 68px;
        .team-section {
            padding-top: 0.5rem;
            break-inside: avoid;
        }
        h2 {
            break-inside: avoid;
            font-size: 1.8rem;
        }
        @include breakpoint(custom, 485) {
            padding-left: 30px;
            h2 {
                font-size: 32px;
            }
        }
        @include breakpoint(custom, 1370) {
            column-count: 1;
        }
    }
    .no-carousel {
        display: grid;
        grid-template-columns: repeat(auto-fit, 250px);
        gap: 0.2rem;
    }
    
    .item {
        position: relative;
        height: 17.5rem;
        overflow: hidden;
        border: 4px solid $grey2;

        @include breakpoint(custom, 1350) {
            height: 18rem;
        }
        
        .block {
            height: 10px;
            background-color: white;
        }
        h3 {
            margin: 0;
            display: block;
            color: white;
            background-color: $grey2;
            padding: 0.5rem 1rem 0rem 1rem;
            font-size: 14px;
            line-height: 1rem;
        }

        p {
            margin: 0;
            padding: 0.5rem 1rem 0rem 1rem;
            font-size: 12px;
            color: $ey-yellow;
        }
        
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            
            &:hover,
            &:focus {
                ~ .item__overlay {
                    transform: translate3d(0, 0, 0);
                }
                ~ .portrait {
                    transform: scale(1.1);
                }
            }
        }
    }
    
    .portrait {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: all 0.8s;
    }
    
    .item__overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        font-size: 14px;
        transition: transform 450ms;
        color: white;
        background-color: $grey2;
        transform: translate3d(0, calc(100% - 3.85rem), 0);
    }
    
    .item__body {
        flex-grow: 1;
        padding: 0.5rem 0rem 1rem 0rem;
        p {
            margin: 0;
            color: white;
        }
    }

    .grid {
        width: 80%;
        padding-top: 30px;
        .slick-slide { 
            height: 280px;
            display: flex;
            @media screen and (max-width: 800px) {
                height: 260px;
            }
            margin: 0 0.2rem;
        }
        .slick-list {
            margin: 0 -0.2rem;
        }
        .slide-arrow {
            position: absolute;
            top: 50%;
            margin-top: -15px;
            cursor: pointer;
            background: transparent;
        }
        .prev-arrow {
            left: -35px;
            width: 0;
            height: 0;
            border-left: 0 solid transparent;
            border-right: 15px solid $grey2;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }
        .next-arrow {
            right: -35px;
            width: 0;
            height: 0;
            border-right: 0 solid transparent;
            border-left: 15px solid $grey2;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }
    }
    .footer-top {
        margin-top: 50px;
    }
}


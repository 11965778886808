.site-map {
    h1.yellow {
        background: $grey2;
        margin: 0;
        padding: 1em 68px 1em;
    }

    ul {
        list-style: none;
        padding: 2em 0em 2em;
        li {
            padding: 1em 0em 1em;

            :hover {
                text-decoration: underline;
            }
        }
    }
    a {
        @include font(20);
        margin: 1.5em 0;
        @include breakpoint(medium, -1) {
            font-size: 1em;
        }
        color: #2e2e38;
        line-height: 1.6;
        text-decoration: none;
        list-style: none;
    }
    @media (max-width: 768px) {
        h1 {
            font-size: 2.5em;
        }
    }
    @media (max-width: 485px) {
        h1 {
            font-size: 2em;
            padding: 2em 68px 1em !important;
        }
    }
}
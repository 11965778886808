// footer-top styles
.footer-top{
    background: $grey2;
    text-align: center;
    padding: 4em 0;
    h3 {
        margin: 0;
        a:hover {
            text-decoration: underline !important;
        }
        + p {
            color:white;
            margin: 1.5em 0;
        }
    }
}
// our-latest-thinking styles
@use "sass:math";

.our-latest-thinking {
    display: none;
    margin: 3em 0;
    h2 {
        margin: 0;
        @include breakpoint(custom, 450) {
            font-size: 1.7rem;
        }
    }
    .tiles {
        @include reset(ul);
        @include clearfix();
        margin-left: -10px;
        li {
            padding-top: 3em;
            float: left;
            width: (100%*0.25);
            @include breakpoint(custom, 1400) {
                width: math.div(100%, 3);
            }
            @include breakpoint(large, 0) {
                width: (100%*0.5);
            }
            @include breakpoint(medium, -1) {
                width: 100%;
                padding-top: 4em;
            }
            @include breakpoint(custom, 450) {
                padding-top: 2em;
            }
            .inner {
                display: block;
                text-decoration: none;
                color: $grey2;
                padding: 0 10px;
                position: relative;
                height: 100%;
                .title {
                    font-weight: bold;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    margin: 0.5em 0 1em;
                    + p {
                        margin: 0.7em 0;
                    }
                }
                .img-container {
                    width: 100%;
                    height: 260px;
                    overflow: hidden;
                    position: relative;
                    .image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: all 0.8s;
                    }
                    .image:hover {
                        transform: scale(1.1);
                    }
                }
                .info {
                    @include clearfix();
                    .description {
                        padding-bottom: 1em;
                        font-size: 14px;
                    }
                    .date,
                    .author {
                        float: left;
                    }
                    .date,.author {
                        position: relative;
                        padding-right: 0.5em;
                        margin-right: 0.5em;
                        &::after {
                            content: "";
                            position: absolute;
                            right: 0px;
                            top: 0px;
                            bottom: 0px;
                            width: 1px;
                            border-right: 1px solid $grey2;
                        }
                    }
                    .author {
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .button {
        margin: 4em auto 1em;
        display: block;
        width: 150px;
        text-align: center;
    }
}

body {
    @include fontstyle(ey);
    font-size: $font_size + px;
    text-rendering: optimizeLegibility;
    color: $grey2;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 300;
    &.yellow {
        color: $ey_yellow;
    }
}

h1 {
    @include font(48, rem);
    @include lineheight(60, rem);
}

h2 {
    @include font(40, rem);
    @include lineheight(50, rem);
}

h3 {
    @include font(32, rem);
    @include lineheight(40, rem);
}

h4 {
    @include font(24, rem);
    @include lineheight(30, rem);
}

h5 {
    @include font(20, rem);
    @include lineheight(28, rem);
}

// Buttons styline
.button {
    display: inline-block;
    @include reset(button);
    padding: 16px 17px;
    border: 1px solid $grey2;
    text-decoration: none;
    transition: all 0.3s;
    color: $grey2;
    cursor: pointer;
    &:hover {
        background: $grey2;
        color: white;
    }
}

hr.seperator {
    margin: 0;
    border: 0;
    border-top: 1px solid $grey4;
}

.wide-search-form {
    position: relative;
    &:after {
        font-family: icons;
        content: $icon-search;
        position: absolute;
        right: 1em;
        top: 50%;
        font-size: 1.5em;
        margin-top: -0.5em;
        opacity: 0.5;
    }
}

.content-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}

input:focus {
    outline: none;
}

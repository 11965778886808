// campaign.details styles
@mixin campaign_page_button_link {
    padding: 0.6em 1em;
    padding-top: 0.5em;
    display: block;
    @media screen and (min-width: 480px) {
        margin: 0 2em;
    }
    background-color: #ffe600;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    // border-radius: 25px;
    @media screen and (min-width: 640px) {
        padding: 1em 2.5em;
        padding-top: 0.9em;
    }
    @media screen and (min-width: 768px) {
        padding: 1em 3em;
        padding-top: 0.9em;
        display: inline-block;
        margin: 0;
        font-size: 24px;
    }

    &:hover {
        text-decoration: underline;
    }
}

.campaign-details {
    // Hide creditor login link.
    header#header .links li {
        a[href^="https://ey.creditors.accountants"] {
            display: none;
        }
    }

    h1 {
        font-size: 32px;
        @media screen and (min-width: 768px) {
            font-size: 44px;
        }
        margin-bottom: 0.2em;
    }
    h2 {
        font-size: 36px;
    }
    a {
        color: #2e2e38;
        &:hover {
            text-decoration: none;
        }
    }
    .margin {
        max-width: 1366px;
        margin: 0 auto;
    }

    #campaign-title {
        padding: 0px;
        font-weight: bold !important;
    }
    .node__title {
        display: none;
    }

    @media print {
        .print {
            display: block;
        }
        .noprint {
            display: none;
        }
        .banner-with-text {
            -webkit-print-color-adjust: exact;
        }
    }
    .banner {
        background-size: cover;
        background-position: center;
        #banner-image {
            height: 23em;
            @include breakpoint(xlarge, 166) {
                height: 15em;
            }
            @include breakpoint(small, 0) {
                height: 10em;
            }

        }
    }
    .campaign-info-container,
    .field--name-body,
    .field--name-field-body-components,
    .field--name-field-components {
        padding: 0 18px;
        @media screen and (min-width: 640px) {
            padding: 0 68px;
        }
    }
    .container-campaign-details {
        @include clearfix();
        font-size: 16px;
        line-height: 1.25;
        @media screen and (min-width: 1024px) {
            font-size: 18px;
        }
        .paragraph--type--emphasis-text {
            font-size: 20px;
        }
        .field--name-field-body-components {
            margin-top: 0.5em;
            > .field__item {
                @media screen and (min-width: 768px) {
                    margin-top: 1em;
                }
            }
        }
        .field--name-field-snippets {
            > .field__item {
                margin-top: 1.2em;
            }
        }
        .paragraph--type--snippet-with {
            &-link, &-image {
                padding: 1.5em;
                // border-radius: 25px;
                @media screen and (min-width: 1024px) {
                    display: flex;
                    gap: 1em;
                }
                > * {
                    @media screen and (min-width: 1024px) {
                        flex: 1 1 0;
                        margin-bottom: 0;
                    }
                    // Right side Image/link is optional.
                    + * {
                        margin-top: 1em;
                        @media screen and (min-width: 1024px) {
                            margin-top: 0;
                            max-width: 30%;
                            align-items: center;
                        }
                    }
                }
                .field--name-field-media-image, .field--name-field-link {
                    text-align: center;
                    @media screen and (min-width: 1024px) {
                        justify-content: right;
                    }
                }
                h2 {
                    font-size: 24px;
                }
                .field--name-field-body {
                    > *:first-child {
                        margin-top: 0;
                    }
                    > *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            &-link {
                background-color: #2e2e38;
                color: #FFFFFF;
                a {
                    color: #FFFFFF;
                }
                .field--name-field-link {
                    display: block;
                    @media screen and (min-width: 1024px) {
                        display: flex;
                    }
                    a {
                        @include campaign_page_button_link();
                    }
                }
            }
            &-image {
                background-color: #ffe600;
                color: #000000;
                h2 {
                    font-weight: 700;
                    line-height: 2.125rem;
                    @media screen and (min-width: 1024px) {
                        font-weight: normal;
                    }
                }
                img {
                    height: auto;
                    max-width: 100%;
                }
            }
        }

        .field--name-field-components {
            > .field__item {
                margin-top: 1.5em;
            }
        }

        .paragraph--type--types-of-services {
            .field--name-field-title {
                text-align: center;
                font-size: 28px;
                margin-bottom: 1em;
                padding-top: 0.1em;
                @media screen and (min-width: 768px) {
                    font-size: 36px;
                }
            }
            .field--name-field-types-of-services {
                display: flex;
                gap: 1em;
                flex-direction: column;
                @media screen and (min-width: 1024px) {
                    flex-direction: row;
                    gap: 0.5em;
                }
                > .field__item {
                    background-color: #2e2e38;
                    color: #FFFFFF;
                    padding: 1.2em 5% 0;
                    // border-radius: 25px;
                    padding-bottom: 1.5em;
                    @media screen and (min-width: 1024px) {
                        flex-grow: 1;
                        flex-basis: 0;
                    }
                    ul {
                        li::marker {
                            content: "▶";
                            font-size: .75rem;
                        }
                        li {
                            span {
                                display: block;
                                padding-left: .5rem;
                            }
                        }
                    }
                    a {
                        color: #FFFFFF;
                    }

                }
            }
            .field--name-field-sub-title {
                text-align: center;
                font-size: 24px;
                margin-bottom: 0.5em;
            }
        }
        .paragraph--type--how-we-work-together {
            @media screen and (min-width: 768px) {
                padding: 0 5%;
            }
            .field--name-field-title {
                text-align: center;
                font-size: 28px;
                padding: 0 0.5em;
                padding-top: 0.1em;
                @media screen and (min-width: 768px) {
                    font-size: 36px;
                }
            }
            .field--name-field-how-we-work-together-step {
                .paragraph--type--how-we-work-together-step {
                    padding: 0.5em 2em;
                    padding-bottom: 0;
                    @media screen and (min-width: 640px) {
                        padding: 1em 3em;
                        display: flex;
                        gap: 2em;
                    }
                    @media screen and (min-width: 768px) {
                        padding: 1em 3em;
                    }


                }
                .group-image {
                    text-align: center;
                    @media screen and (min-width: 640px) {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    .field--name-field-media-image {
                        @media screen and (min-width: 640px) {
                            width: 113px;
                        }
                    }
                }
                .field--name-field-sub-title {
                    font-size: 23px;
                    font-weight: bold;
                    text-transform: uppercase;
                    margin-top: 0.2em;
                }
            }
        }
        .paragraph--type--call-to-action-link {
            background-color: #2e2e38;
            padding: 1.5em 1em;
            text-align: center;
            a {
                @include campaign_page_button_link();
            }
        }
        .paragraph--type--further-enquiries {
            text-align: center;
            padding-left: 2em;
            padding-right: 2em;
        }
        .paragraph--type--about-ey {
            background-color: #2e2e38;
            color: #FFFFFF;
            padding: 2em 10%;
            @media screen and (min-width: 768px) {
                padding: 2em 5%;
            }
            a {
                color: #FFFFFF;
            }

            .field--name-field-title {
                font-size: 38px;
                text-align: center;
                @media screen and (min-width: 768px) {
                    text-align: left;
                }
            }
            .field--name-field-about-ey-persons {
                display: flex;
                flex-direction: column;
                margin: 0 0.5em 0;
                @media screen and (min-width: 1024px) {
                    gap: 1.7em;
                    margin: 1em 1em 0;
                }
            }
            .paragraph--type--about-ey-person {
                @media screen and (min-width: 640px) {
                    display: flex;
                    gap: 2em;
                }
                .field--name-field-media-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

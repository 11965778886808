// people.details styles
.people-details {
    .container-case-details {
        display: flex;
        @include clearfix();
        h5 {
            margin: 1em 0 0.5em;
        }
        @include breakpoint(medium, -1) {
            flex-direction: column;
            h1 {
                font-size: 2em;
            }
            h3 {
                font-size: 1.5em;
                line-height: 1.75rem;
            }
            h4 {
                font-size: 1.3em;
                line-height: 1.5rem;
            }
            p {
                font-size: 1em;
            }
        }
        .image{
            width: 308px;
            height: 308px;
            border-radius: 50%;
            margin-bottom: 3em;
            background-size: cover;
            @include breakpoint(medium, -1) {
                margin: 0 auto 1em;
                background-size: cover;
                max-width: calc(100vw * 0.85 * 0.75);
                max-height: calc(100vw * 0.85 * 0.75);
            }
        } 
    }
    .textblock {
        @include breakpoint(medium, -1) {
            padding-bottom: 0;
            padding-top: 5em;
        }
    }
    .sideblock {
        flex: 1;
        float: right;
        min-width: 250px;
        min-height: 250px;
        max-width: 520px;
        margin: 4em 0 4em 4em;

        @include breakpoint(medium, 111) {
            max-width: 45%;
            margin: 4em 0 2em 2em;
        }
        @include breakpoint(medium, -1) {
            min-width: none;
            max-width: none;
            width: 100%;
            margin: 0;
        }

        .tags {
            max-width: 60%;
            @include reset(ul);
            padding: 1em 0;
            .title {
                display: block;
                font-weight: bold;
                margin-bottom: 1em;
            }
            a {
                display: inline-block;
                padding: 0.3em 1em !important;
                border-radius: 3px;
                border: 1px solid $grey2 !important;
                color: $grey2;
                text-decoration: none;
                margin: 0.3em 0 0;
                &:last-child {
                    margin-bottom: 0.3em;
                }
            }
        }
        .address{
            padding: 1em 0;

            .title{
                display: block;
                font-weight: bold;
                margin-bottom: 1em;
            }
        }
        .share {
            padding: 0.5em 0 1em;
            .title {
                display: block;
                font-weight: bold;
                margin-bottom: 1em;
            }
            a {
                display: inline-block;
                margin-right: 1em;
            }
            .tooltip:hover .tooltiptext {
                visibility: visible;
            }
            .tooltiptext {
                position: absolute;
                display: block;
                width: auto;
                background-color: $grey2;
                color: #fff;
                border-radius: 5px;
                padding: 2px;
                line-height: 24px;
                text-align: top;
                visibility: hidden;
                margin: 0 10px 0 10px;
                p {
                    margin: 3px 3px 3px 3px;
                }
            }
        }
    }

    .more-info-cta {
        margin: 2em auto 1em;
        display: block;
        @include font(20);
        padding: 16px 2em;
    }
}

// redirect styles


.redirect-to-public{
    position: absolute;
    width: 200px;
    background: $grey2;
    color: white;
    font-weight: bold;
    left: 50%;
    padding: 30px;
    box-sizing: border-box;
    margin-left: -100px;
    border-radius: 3px;
    text-align: center;
    top: 40%;
}
.loader {
    .background {
        position: fixed;
        padding: 0;
        margin: 0;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: white;
        z-index: 100;

        .loading-animation { 
            border: 16px solid #f3f3f3; /* Light grey */
            border-top: 16px solid $grey2; /* Blue */
            border-radius: 50%;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
            margin: -76px 0 0 -76px;
            position: absolute;
            left: 50%;
            top: 50%;
        }
        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }
    }
}


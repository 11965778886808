/* tab setting */
@use "sass:math";

$tab-count: 4;
$indicator-width: 50px;
$indicator-height: 4px;

/* breakpoints */
$breakpoints: (
  medium: #{$tab-count*250px},
  small: #{$tab-count*150px}
);

/* selectors relative to radio inputs */
$label-selector: "~ ul > li";
$slider-selector: "~ .slider";
$content-selector: "~ .content > section";

@mixin tabs(
  $label-selector: $label-selector,
  $slider-selector: $slider-selector,
  $content-selector: $content-selector) {
    
  @for $i from 1 through $tab-count {
    &:nth-of-type(#{$i}):checked {
      #{$label-selector}:nth-child(#{$i}) {
        @content;
      }

      #{$slider-selector} {
        transform: translateX(#{100% * ($i - 1)});
      }

      #{$content-selector}:nth-child(#{$i}) {
        display: block;
      }
    }
  }
}

.formal-insolvency {
    .tabs {
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        background: white;
        padding-bottom: 40px;
        height: auto;
        border-radius: 5px;
        min-width: #{$tab-count * 60px};
        overflow: hidden;
        input[name="tab-control"] {
            display: none;
        }
        @media screen and (max-width: 920px) {
          left: 45%;
        }
    }
    .tab-list  {
        list-style-type: none;
        padding-left: 0;
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        padding: 5px;
        @media (max-width: 920px) {
          flex-direction: column;
        }
        .tab {
            box-sizing: border-box;
            width: #{math.div(100%, $tab-count)};
            text-align: center;
            @media (max-width: 920px) {
              padding-bottom: 20px;
              width: 100%;
            }
            
            label {
              transition: all 0.3s ease-in-out;
              color: white;
              background-color: $grey2;
              padding: 5px auto;
              height: 80px;
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              justify-content: center;
              align-items: center;              
              cursor: pointer;
              transition: all 0.2s ease-in-out;
              white-space: nowrap;
              font-size: 20px;
              -webkit-touch-callout: none;
              @media (max-width: 1150px) {
                font-size: 16px;
              }
            }
        }
        :first-child {
            label {
                margin-left: 0;
            }
        }
        :last-child {
            label {
                margin-right: 0;
            }
        }
    }
    .content {
        margin-top: 30px;
        
        section {
          display: none;
          animation: {
            name: content;
            direction: normal;
            duration: 0.3s;
            timing-function: ease-in-out;
            iteration-count: 1;
          }
          line-height: 1.4;
          img {
              display: block;
              margin: auto;
          }
        }

        img[alt="voluntary-administration"] {
          width:50%;
          height:50%;
        }
    }
    .form-ins-btns {
        margin: auto;
        width: 80%;
        display: flex;
        position: relative;
        justify-content: space-around;
        .btn-label {
          margin: 10px 20px;
        }
        button {
          width: 250px;
        }
        @media (max-width: 1080px) {
          width: 90%;
        }
        @media (max-width: 720px) {
          width: 100%;
          button {
            font-size: 14px;
            width: 225px;
          }
        }
        @media (max-width: 550px) {
            display: block;
            width: 300px;
            button {
              text-align: center;
              display: block;
              width: 100%;
              font-size: 14px;
              padding: 16px 8px;
              margin-bottom: 30px;
            }
        
        }
    }

    /* Accordion styles */

    .accordion-title{
      cursor:pointer;
      font-size: 1.5rem;
    }
    .accordion-title::after{
      content:"+";
      float:right;
      margin-right:40px;
    }
    .accordion-title-open::after{
      content:"-";
      float:right;
      margin-right:40px;
    }
    @media screen and (max-width: 550px) {
      .accordion-title::after {
        content: none;
      }
      .accordion-title-open::after {
        content: none;
      }
    }
    .accordion-holder{
      margin-top:20px;
      margin-bottom:20px;
      padding-left: 40px;
      border-top: solid 1px #DFDFDF;
      border-bottom: solid 2px #DFDFDF;
      border-left: solid 4px #389ACB;
    }
    .accordion-holder p { padding-bottom:20px; }
    .accordion-content-holder{    
      height:0px;
      overflow:hidden;
    }
    .accordion-content-holder.active{    
      height: auto;
    }
    .accordion-content img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    /* End Accordion styles */
}

input[name="tab-control"] {
    @include tabs {
      > label {
        cursor: default;
        color: $grey2;
        background-color: $ey-yellow;
      }
    }
  }
// current.cases styles
.current-cases {
    h1 {
        line-height: 1.15;
    }
    @media (max-width: 1024px) {
        h1 {
            font-size: 2.5rem;
        }
        .description {
            font-size: 18px;
        }
    }
    @media (max-width: 485px) {
        h1 {
            font-size: 2rem;
        }
        .description {
            font-size: 14px;
        }
    }
    .drop-down-box {
        @media (max-width: 450px) {
            left: 70px !important;
        }
    }
    
    .current-cases-search-form {
        label {
            display: none;
        }
        input {
            padding: 30px 0;
            border: 0px;
            @include font(24);
            width: 100%;
            line-height: 1.3em;
            
            @include breakpoint(custom, 530) {
                padding: 1.5em 0;
            }
        }

        &.wide-search-form:after, input {
            @include breakpoint(custom, 530) {
                font-size: 1em;
            }
            @include breakpoint(custom, 380) {
                font-size: 0.8em;
            }
        }
    }
    .engagements {
        display: list-item;
    }
    .link {
        cursor: pointer;
    }  
}

$icomoon-font-family: "icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-swap-vertical-orientation-arrows: "\e91c";
$icon-double-up-and-down-arrow-between-two-horizontal-bars: "\e91d";
$icon-arrows: "\e91e";
$icon-resize: "\e91a";
$icon-expand: "\e91b";
$icon-pagination: "\e919";
$icon-sign-out: "\e924";
$icon-filter1: "\e917";
$icon-close2: "\e914";
$icon-menu1: "\e915";
$icon-flag: "\e911";
$icon-Fill-11: "\e910";
$icon-download: "\e90f";
$icon-Fill-1: "\e90c";
$icon-Group-3: "\e90d";
$icon-down: "\e90b";
$icon-search: "\e906";
$icon-Group-6: "\e907";
$icon-Group-7: "\e908";
$icon-Group: "\e909";
$icon-chevron-right: "\e901";
$icon-chevron-left: "\e902";
$icon-chevron-down: "\e903";
$icon-chevron-up: "\e904";
$icon-filter: "\e916";
$icon-check_box_outline_blank: "\e921";
$icon-check_box: "\e922";
$icon-filter_list: "\e918";
$icon-view_column: "\e913";
$icon-dashboard1: "\e90a";
$icon-close: "\e90e";
$icon-exit: "\e923";
$icon-checkbox-unchecked: "\e91f";
$icon-checkbox-checked: "\e920";
$icon-calendar: "\e912";
$icon-user-tie: "\e905";
$icon-home: "\e900";
$icon-house: "\e900";


.field--name-field-media-oembed-video {
  width: 70%;
  padding-top: 39.5%;
  position: relative;
  height: 0px;
  margin: auto;
}

.media-oembed-content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

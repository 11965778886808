// footer-bottom styles
.footer-bottom {
    @include clearfix();
    .left,
    .right {
        box-sizing: border-box;
        float: left;
        width: 50%;
    }
    .container-outer {
        padding-top: 2em;
        padding-bottom: 2em;
    }
    .wrapper {
        @include clearfix();
        padding: 1em 0 0;
    }
    .top-wrapper {
        .left {
            display: block;
            background-image: url('/images/logo-stack.svg');
            background-repeat: no-repeat;
            background-size: 90px;
            height: 110px;
        }
    }
    .left {
        // @include breakpoint(medium, -1) {
        //     img {
        //         width: 30%;
        //         // height: 70%;
        //     }
        // }
        p {
            margin: 0;
            @include font(14);
            color: $grey5;
            @include breakpoint(medium, -1) {
                font-size: 14px*0.85;
            }
            @include breakpoint(custom, 600) {
                font-size: 14px*0.7;
            }
        }
    }

    .right {
        .links {
            float: right;
            clear: both;
            @include reset(ul);
            margin-top: 1em;
            li {
                float: left;
                margin-left: 1em;
                &:first-child {
                    margin-left: 0;
                    //max width 1400px
                    @include breakpoint(large, 376) {
                        margin-left: 1em;
                    }
                }
                a {
                    text-decoration: none;
                    color: $grey2;
                    font-weight: bold;
                    @include hover {
                        text-decoration: underline;
                    }
                    @include breakpoint(medium, -1) {
                        font-size: 0.85em;
                    }
                    @include breakpoint(custom, 600) {
                        font-size: 0.7em;
                    }
                }
            }
            &.icons {
                margin-top: 0em;

                li {
                    margin-left: 0.5em;
                    @include breakpoint(medium, -1) {
                        width: 20%;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            .facebook {
                a {
                    display: block;
                    background-image: url("/images/facebook.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 45px;
                    height: 45px;
                    margin: 0 2px 0 2px;
                }
            }
            .twitter {
                a {
                    display: block;
                    background-image: url("/images/twitter.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 45px;
                    height: 45px;
                    margin: 0 2px 0 2px;
                }
            }
            .linkedin {
                a {
                    display: block;
                    background-image: url("/images/linkedin.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    width: 45px;
                    height: 45px;
                    margin: 0 2px 0 2px;
                }
            }
        }
    }
    // @media (min-width: 768px) and (max-width: 1024px) {
    //     .wrapper {
    //         font-size: 0.7em;
    //         .left {
    //             img {
    //                 //EY img width might be too small in relation to its container
    //                 width: 17.5%;
    //             }
    //         }
    //         .right {
    //             .links {
    //                 li {
    //                     &:first-child {
    //                         margin-left: 1em;
    //                     }
    //                 }
    //                 &.icons {
    //                     //making the width of .icons smaller, so that the img width can be made smaller while still taking 100% of its container 
    //                     width: 50%;
    //                     li {
    //                         width: 20%;
    //                         // margin-left: 1em;
    //                         img {
    //                             width: 100%;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
}

// how-can-we-help styles
.how-can-we-help {
    display: none;
    margin: 5em 0;
    h2 {
        margin: 0;
        + p {
            @include font(20);
            margin: 1.5em 0;
            @include breakpoint(medium, -1) {
                font-size: 1em;
            }
        }
        @include breakpoint(custom, 767) {
            font-size: 1.8rem;
        }
        @include breakpoint(custom, 450) {
            font-size: 1.6rem;
        }
    }
    .categories {
        @include reset(ul);
        max-width: 70%;
        display: block;
        margin: 0 auto;
        padding: 1em 0;
        @include clearfix();
        @include breakpoint(large, 0) {
            max-width: 100%;
        }
        li {
            float: left;
            width: 50%;
            padding: 3em 0;
            display: flex;
            @include clearfix;
            position: relative;
            @include breakpoint(large, 0) {
                font-size: 80%;
            }
            @include breakpoint(medium, 132) {
                font-size: 70%;
                padding-bottom: 0;
            }
            @include breakpoint(medium, 22) {
                font-size: 60%;
            }
            @include breakpoint(custom, 400) {
                font-size: 50%;
            }
            //change into one li element per row
            @include breakpoint(small, 50) {
                width: 100%;
                //may not need to change padding-top
                padding-top: 2.5em;
            }
            .img-container {
                height: 5rem;
                flex: 2;
                img {
                    float: left;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            
            p {
                flex: 7;
                margin: 0;
                padding-left: 20px;
                // @include font(20);
                // em unit is small on mobile.
                font-size: 1rem;
                //max-width 1240px
                @include breakpoint(large, 216)  {   
                    padding-right: 20px;
                }
            }
        }
    }
    .grid {
        padding-top: 3em;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
        gap: 2em;
        @include breakpoint(custom, 760) {
            grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
            padding-right: 1em;
            padding-left: 1em;
        }
        .info-panel {
            cursor: pointer;
            position: relative;
            padding-bottom: 20px;
            border-radius: 15px;
            background-color: $grey2;
            color: white;
            height: 60px;
            transition: 0.3s;
            overflow: hidden;
            text-overflow: ellipsis;
            h2 {
                font-size: 20px;
                line-height: 1.5rem;
                margin: 15px 40px 15px 15px;
                display: inline-block;
            }
            p {
                font-size: 15px;
                margin: 20px 15px 5px 15px;
                display: none;
            }
            .plus-icon {
                position: absolute;
                float: right;
                top: 10px;
                right: 15px;
                font-size: 30px;
            }
            @media screen and (max-width: 760px) {
                height: 45px;
                h2 {
                    font-size: 14px;
                    margin: 10px 40px 15px 15px;
                }
                p {
                    margin: 10px 15px 5px 15px;
                    font-size: 12px;
                }
                .plus-icon {
                    font-size: 24px;
                }
            }
            @media screen and (max-width: 545px) {
                height: 40px;
                h2 {
                    font-size: 13px;
                }
                p {
                    margin: 0px 15px 5px 15px;
                }
            }

        }
    }
}

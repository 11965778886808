.legal-and-privacy {
    h1.yellow {
        background: $grey2;
        margin: 0;
        padding: 1em 68px 1em;
    }

    p {
        @include font(18);
        margin: 1.5em 0;
        @include breakpoint(medium, -1) {
            font-size: 1em;
        }
    }

    li {
        @include font(16);
        padding: 0.25em 0em 0.25em;
    }

    a {
        font-weight: bold;
        color: $grey2;
    }
    @media (max-width: 768px) {
        h1 {
            font-size: 2.5em;
        }
    }
    @media (max-width: 485px) {
        h1 {
            font-size: 2em;
            padding: 2em 68px 1em !important;
        }
    }
}
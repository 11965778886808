// disclaimer styles
.disclaimer {
    position: fixed;
    top: 100px;
    width: 920px;
    left: 50%;
    margin-left: -460px;
    box-sizing: border-box;
    padding: 4em;
    z-index: 3000;
    background: white;
    @include clearfix();
    display: none;
    &.overlay-active {
        display: block;
    }
    #disclaimerbody {
        font-size: 16px;
    }
    @include breakpoint(custom, 1280) {
        font-size: 60%;
    }
    @include breakpoint(large, 0) {
        width: 70%;
        margin-left: 0;
        left: 15%;
    }
    
    @include breakpoint(medium, -1) {
        top: 40px;
    }
    @include breakpoint(small, 20) {
        font-size: 50%;
        padding: 3em;
    }
    

    .title {
        text-align: center;
        font-weight: bold;
        margin: 0 0 1.2em;
        @include breakpoint(small, 20) {
            font-size: 1rem;
        }
    }
    p {
        @media screen and (min-width: 1281px) {
            font-size: 20px;
        }
        @include breakpoint(custom, 420) {
            font-size: 0.75rem
        }
    }

    form {
        .item {
            padding: 1em 0 0;
            @include font(20);
            font-weight: bold;
            label,
            input {
                cursor: pointer;
            }
        }
        #btn {
            float: right;
            @include font(20);
            font-weight: bold;
            @include breakpoint(small, 20) {
                padding: 1em 1em;
            }
            @include breakpoint(custom, 420) {
                margin-top: 1em;
            }
            &:hover {
                background: $grey2 !important;
                color: white !important;
            }
            pointer-events:none;
            color: grey;
        }
    }
    
    .close {
        position: absolute;
        margin-block-start: 0.53em;
        margin-block-end: 0.53em;
        top: 0;
        right: 0;
        padding-right: 30px;
        cursor: pointer;
        font-family: Arial Unicode MS;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
    }
}

@use "sass:math";

.clear-float {
    display: block;
    clear: both;
}
.clearfix {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}
.float {
    &-right {
        float: right;
    }
    &-left {
        float: left;
    }
}
.no-padding {
    padding: 0 !important;
    &-top {
        padding-top: 0 !important;
    }
    &-left {
        padding-left: 0 !important;
    }
    &-right {
        padding-right: 0 !important;
    }
    &-bottom {
        padding-bottom: 0 !important;
    }
}

.no-margin {
    margin: 0 !important;
    &-top {
        margin-top: 0 !important;
    }
    &-left {
        margin-left: 0 !important;
    }
    &-right {
        margin-right: 0 !important;
    }
    &-bottom {
        margin-bottom: 0 !important;
    }
}

.no-border {
    border: 0 !important;
    &-top {
        border-top: 0 !important;
    }
    &-left {
        border-left: 0 !important;
    }
    &-right {
        border-right: 0 !important;
    }
    &-bottom {
        border-bottom: 0 !important;
    }
}

.align {
    &-center {
        text-align: center;
    }
    &-left {
        text-align: left;
    }
    &-right {
        text-align: right;
    }
}

.bold {
    font-weight: bold;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.rotate {
    &-90 {
        transform: rotate(90deg);
    }
    &-270 {
        transform: rotate(270deg);
    }
}

.spacer {
    &-top {
        padding-top: 15px;
    }
    &-bottom {
        padding-bottom: 15px;
    }
}

.width {
    &_half {
        width: 50%;
    }
    &_two_third {
        width: math.div(100%, 3) * 2;
    }
    &_one_third {
        width: math.div(100%, 3) * 1;
    }
}


.padding {
    box-sizing: border-box;
    &.right-5 {
        padding-right: 5px;
    }
    &.left-5 {
        padding-left: 5px;
    }
    &.right-10 {
        padding-right: 10px;
    }
}
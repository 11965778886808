// the-team styles
// our-latest-thinking styles
.the-team {
    display: none;
    margin: 3em 0;
    h2 {
        margin: 0;
        @include breakpoint(custom, 450) {
            font-size: 1.7rem;
        }
    }

    * {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        padding: 3rem;
    }
    
    .grid {
        padding-top: 3em;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
        gap: 1rem;
        @include breakpoint(custom, 610) {
            grid-template-columns: repeat( auto-fit, minmax(175px, 1fr) );
        }
        @include breakpoint(custom, 524) {
            grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
            padding-right: 1em;
            padding-left: 1em;
        }
    }
    
    .item {
        position: relative;
        height: 21rem;
        overflow: hidden;
        border: 4px solid $grey2;

        @include breakpoint(custom, 524) {
            height: auto;
        }
        
        .block {
            height: 10px;
            background-color: white;
        }
        h3 {
            margin: 0;
            display: block;
            color: white;
            background-color: $grey2;
            padding: 0.5rem 1rem 0rem 1rem;
            font-size: 14px;
            line-height: 1rem;
        }

        p {
            margin: 0;
            padding: 0.5rem 1rem 0rem 1rem;
            font-size: 12px;
            color: $ey-yellow;
        }
        
        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            
            &:hover,
            &:focus {
                ~ .item__overlay {
                    transform: translate3d(0, 0, 0);
                }
                ~ .portrait {
                    transform: scale(1.1);
                }
            }
        }
    }
    
    .portrait {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        transition: all 0.8s;
    }
    
    .item__overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        font-size: 14px;
        transition: transform 450ms;
        color: white;
        background-color: $grey2;
        transform: translate3d(0, calc(100% - 3.85rem), 0);
    }
    
    .item__body {
        flex-grow: 1;
        padding: 0.5rem 0rem 1rem 0rem;
        p {
            margin: 0;
            color: white;
        }
    }
}
  
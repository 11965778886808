// Website container
body {
    padding-top: 85px;
}

.layout-container {
    max-width: 1920px;
    box-sizing: border-box;
    margin: 0 auto;
}

.container-outer {
    padding: 0 68px;
    box-sizing: border-box;
    @include breakpoint(medium, -1) {
        padding: 0 7.5vw;
    }
}


// header styles
header#header {
    background: $grey2;
    height: 85px;
    position: fixed;
    z-index: 500;
    top: 0;
    width: 100%;
    max-width: 1920px;
    @include clearfix();

    .logo {
        background-size: auto 100%;
        background-position: left center;
        background-repeat: no-repeat;
        text-decoration: none;
        float: left;
        margin: 13px 30px;
        width: 158px;
        img {
            width: auto;
            height: 100%;

        }
        span {
            position: absolute;
            opacity: 0;
            font-size: 0.1em;
        }
    }
    .links {
        @include reset(ul);
        float: right;
        padding-right: 30px;
        @include breakpoint(custom, 1122) {
            padding-right: 0px;
            margin-bottom: 13px;
        }
        li {
            float: left;
            a {
                display: block;
                color: white;
                padding: 12px 17px;
                @include font(20);
                margin: 14px 0 0;
                box-sizing: border-box;
                border: 1px solid transparent;
                transition: all 0.3s;
                background: transparent;
                @include hover {
                    color:$ey_yellow
                }
                @include breakpoint(custom, 1024) {
                    font-size: 18px;
                    padding: 8px 8px;
                }
                @include breakpoint(medium, -1) {
                    font-size: 12px;
                }

            }
            &:last-child {
                a {
                    text-decoration: none;
                    border-color: $ey_yellow;    
                    color:$ey_yellow;
                    @include hover{
                        background-color: $ey_yellow;
                        color:$grey2
                    }
                }
            }
            a[href="/current.cases.html"]
            {
                display: none;
            }
        }
        .creditor-login-link {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    /* Style the navigation menu */
    .topnav {
        overflow: hidden;
        position: relative;
    }
    
    /* Hide the links inside the navigation menu (except for logo/home) */
    .topnav #myLinks {
        display: none;
    }
    
    /* Style the hamburger menu */
    .topnav a.icon {
        display: none;
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 48px;
        color: white;
    }
    
    /* Style the active link (or home/logo) */
    .active {
        background-color: #04AA6D;
        color: white;
    }
    /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
    @media screen and (max-width: 1122px) {
        .topnav {
            .links li {
                display: none;
            }
            a.icon {
                float: right;
                display: block;
            }
        }
    }
    
    /* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
    @media screen and (max-width: 1122px) {
        .topnav li:hover {
            a:hover {
                background-color: $ey_yellow;
                color:$grey2
            }
        }
        .topnav.hamburger-menu {position: relative;}
        .topnav.hamburger-menu a.icon {
            position: absolute;
        }
        .topnav.hamburger-menu {
            .links {
                float: none;
                background-color: #2e2e38;
                padding-top: 10px;
                padding-bottom: 20px;

                li:nth-child(1) {
                    margin-top: 100px;
                }
                li {
                    float: none;
                    display: block;
                    text-align: center;

                    a {
                        text-decoration: none;
                        font-size: 18px;
                        border-bottom: 1px solid rgba(255,255,255,.2);
                        margin: 0;
                    }
                    &:first-child {
                        a {
                            border-top: 1px solid rgba(255,255,255,.2);
                        }
                    }
                    &:last-child {
                        a {
                            text-decoration: none;
                            border-color: $ey_yellow;    
                            color:$ey_yellow;
                            @include hover{
                                background-color: $ey_yellow;
                                color:$grey2
                            }
                        }
                    }
                }
                
            }
        }
    }
}
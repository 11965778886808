@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2e4qpz');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2e4qpz#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2e4qpz') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2e4qpz') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2e4qpz##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-swap-vertical-orientation-arrows {
  &:before {
    content: $icon-swap-vertical-orientation-arrows; 
  }
}
.icon-double-up-and-down-arrow-between-two-horizontal-bars {
  &:before {
    content: $icon-double-up-and-down-arrow-between-two-horizontal-bars; 
  }
}
.icon-arrows {
  &:before {
    content: $icon-arrows; 
  }
}
.icon-resize {
  &:before {
    content: $icon-resize; 
  }
}
.icon-expand {
  &:before {
    content: $icon-expand; 
  }
}
.icon-pagination {
  &:before {
    content: $icon-pagination; 
  }
}
.icon-sign-out {
  &:before {
    content: $icon-sign-out; 
  }
}
.icon-filter1 {
  &:before {
    content: $icon-filter1; 
  }
}
.icon-close2 {
  &:before {
    content: $icon-close2; 
  }
}
.icon-menu1 {
  &:before {
    content: $icon-menu1; 
  }
}
.icon-flag {
  &:before {
    content: $icon-flag; 
  }
}
.icon-Fill-11 {
  &:before {
    content: $icon-Fill-11; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-Fill-1 {
  &:before {
    content: $icon-Fill-1; 
  }
}
.icon-Group-3 {
  &:before {
    content: $icon-Group-3; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-Group-6 {
  &:before {
    content: $icon-Group-6; 
  }
}
.icon-Group-7 {
  &:before {
    content: $icon-Group-7; 
  }
}
.icon-Group {
  &:before {
    content: $icon-Group; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank; 
  }
}
.icon-check_box {
  &:before {
    content: $icon-check_box; 
  }
}
.icon-filter_list {
  &:before {
    content: $icon-filter_list; 
  }
}
.icon-view_column {
  &:before {
    content: $icon-view_column; 
  }
}
.icon-dashboard1 {
  &:before {
    content: $icon-dashboard1; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-exit {
  &:before {
    content: $icon-exit; 
  }
}
.icon-checkbox-unchecked {
  &:before {
    content: $icon-checkbox-unchecked; 
  }
}
.icon-checkbox-checked {
  &:before {
    content: $icon-checkbox-checked; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-user-tie {
  &:before {
    content: $icon-user-tie; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-house {
  &:before {
    content: $icon-house; 
  }
}


$red1: #ff0000; // rgba(100%,0%,0%,1.00);

$grey1: #4a4a4a; // rgba(29%,29%,29%,1.00);
$grey2: #2e2e38;
$grey3: #3d3e42;
$grey4: #979797;
$grey5: #747480;
$grey6: #eaeaf2;

$ey_yellow: #ffe600;

$teal1: #00a7ba;

// drop-down-box styles
.drop-down-box-container {
    position: relative;
    &.overlay-active {
        z-index: 2000;
    }
    .drop-down-box {
        position: absolute;
        left: 0;
        margin-top: 10px;
        min-width: 0;
        min-height: 0;
        border-radius: 5px;
        z-index: 2000;
        background-color: #ffffff;
        box-shadow: 0;
        opacity: 0;
        overflow: hidden;
        height: 0px;
        transition: all 0.3s;
        @include breakpoint(small) {
            top: 0;
            position: fixed;
            margin: 0;
            border-radius: 0;
            right: 0;
            left: 100px;
            bottom: 0;
            box-shadow: none !important;
            // height: 100% !important;
            box-sizing: border-box;
            z-index: 2000;
        }
        @include breakpoint(custom, 450) {
            left: 70px;
        }
        &.overlay-active {
            min-width: 200px;
            min-height: 50px;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
            height: auto;

            opacity: 1;
        }
        .inner {
            padding: 1.5em;
            min-width: 250px;
            @include clearfix();
            .close {
                position: absolute;
                top: 0;
                right: 0;
                /* margin: -5px; */
                /* padding: 5px; */
                font-weight: bold;
                padding: 0.8em 1em;
                i {
                    margin: 0;
                }
                span {
                    position: absolute;
                    text-indent: -9999px;
                }
            }
            .heading {
                display: none;
                margin-top: 0;
                @include breakpoint(small) {
                    display: block;
                }
            }
        }

        form {
            .item {
                // padding: 5px 0;
                padding: 3px 0;
                &:first-child {
                    padding-top: 0;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
            label {
                display: inline-block;
                @include font(12);
                margin: 0 0 0.3em;
                font-weight: 600;
                text-transform: uppercase;
            }

            select,
            input[type="text"],
            input[type="date"],
            input[type="number"] {
                width: 100%;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid #cdcdcd;
                // font-size: 0.9em;
                padding: 0.5em;
                border-radius: 3px;

                // Remove the number spinner
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            input[type="submit"] {
                border: 1px solid #2e2e38;
                border-radius: 3px;
                background: white;
                transition: all 0.3s;
                background: #2e2e38;
                color: white;
                float: right;
                padding: 0.7em 0.8em;
                font-size: 0.8em;
                font-family: "Montserrat", sans-serif;
                margin-top: 0.5em;
                cursor: pointer;

                @include hover() {
                    background: white;
                    color: #2e2e38;
                }
            }

            &.checkboxes {
                input[type="checkbox"] {
                    display: none;
                    &:checked {
                        + label {
                            font-weight: bold;
                            &::before {
                                opacity: 1;
                                content: $icon-check_box;
                            }
                        }
                    }
                    + label {
                        position: relative;
                        padding-left: 1.5em;
                        text-transform: none;
                        // font-size: 0.9em;
                        &::before {
                            transition: all 0.3s;
                            content: $icon-check_box_outline_blank;
                            font-family: icons;
                            // margin-right: 5px;
                            opacity: 0.1;
                            float: left;
                            margin: 2px 8px 0 0;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                label {
                    display: inline-block;
                    @include font(16);
                    cursor: pointer;
                    font-weight: normal;
                }
            }

            &.radioboxes {
                input[type="radio"] {
                    display: none;
                    &:checked {
                        + label {
                            // text-transform: none;
                            font-weight: bold;

                            &::before {
                                content: $icon-Fill-11;
                                font-family: icons;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                label {
                    display: inline-block;
                    @include font(16);
                    cursor: pointer;
                    text-transform: none;
                    font-weight: normal;
                }
            }
        }

        .datetable-injected-pagination-placeholder {
            label {
                display: inline-block;
                @include font(12);
                margin: 0 0 0.3em;
                font-weight: 600;
                text-transform: uppercase;
            }
            select {
                font-size: 1.3em;
                font-weight: normal;
                margin: 0 3px;
                padding: 0.2em;
                cursor: pointer;
                border: 1px solid #cdcdcd;
            }
        }

        .datetable-injected-search-placeholder {
            input {
                width: 100%;
                padding: 5px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                border: 1px solid #cdcdcd;
                padding: 0.5em 0;
                /* border-radius: 3px; */
                /* margin: 0.5em 0; */
                border: 0;
                border-bottom: 1px solid #cdcdcd;
                outline: none;
            }
        }
    }
    .drop-down-box-toggle {
        position: relative !important;
        padding-right: 20px !important;
        text-align: left;
        @include breakpoint(small) {
            display: block;
            width: 100%;
        }
        > span {
            &.selection {
                display: block;
                opacity: 0.5;
                padding: 0.2em 0 0;
                max-width: 200px;
                font-size: 0.9em;
                font-weight: normal;
            }
            @include breakpoint(small) {
                @include font(18);
                display: block;
                text-align: left;
                font-weight: bold;
            }
        }

        i {
            transition: all 0.3s;
            display: inline-block;
            position: absolute;
            right: 0;
            top: 3px;
            transform: rotate(0deg);
            @include breakpoint(small) {
                font-size: 1.2em;
                &.icon-chevron-down {
                    transform: rotate(-90deg);
                }
            }
        }

        &.overlay-active {
            position: relative;
            z-index: 2000;
            i {
                transform: rotate(180deg);
                &.full-rotate {
                    transform: rotate(360deg);
                }
            }
        }
    }
    &.box-right {
        .drop-down-box {
            left: auto;
            right: 0;
        }
    }
}

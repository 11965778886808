@mixin h4_p_font_large_screen {
    font-size: 1rem;
}
@mixin h4_p_font_500px {
    font-size: 0.7rem;
}
// homepage-banner styles
.homepage-banner {
    display: none;
    position: relative;
    margin-bottom: 3em;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: $grey2;
        height: 60%;
    }
    .container-outer {
        position: relative;
        z-index: 100;
    }
    h1 {
        margin: 0;
        padding: 1.6em 0 0em;
        @include breakpoint(large, 0) {
            padding: 1.2em 0 0em;
        }
        @include breakpoint(medium, -1) {
            padding: 1em 0 0em;
            font-size: 2rem;
        }
        + p {
            @include font(24);
            color: white;
            margin: 1.6em 0;
            @include breakpoint(large, 0) {
                @include h4_p_font_large_screen();
            }
            @include breakpoint(custom, 500) {
                font-size: 14px;
            }
        }
        @include breakpoint(custom, 450) {
            font-size: 1.7rem;
        }
    }

    .carousel-container {
        display: none;
        width: 100%;
        padding-top: 30px;
        .article-carousel {
            background-color: $grey6;
            width: 80%;
            margin: 0px auto;
            height: 280px;
            @media screen and (max-width: 800px) {
                height: 260px;
            }
        }
        .slick-slide { 
            height: 280px;
            display: flex;
            @media screen and (max-width: 800px) {
                height: 260px;
            }
        }
        .slide-arrow {
            position: absolute;
            top: 50%;
            margin-top: -15px;
            cursor: pointer;
            background: transparent;
        }
        .prev-arrow {
            left: -40px;
            width: 0;
            height: 0;
            border-left: 0 solid transparent;
            border-right: 15px solid $grey2;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }
        .next-arrow {
            right: -40px;
            width: 0;
            height: 0;
            border-right: 0 solid transparent;
            border-left: 15px solid $grey2;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
        }
        .slick-dots {
            padding-top: 10px !important;
            line-height: 10px;
            font-size: 20px;
            text-align: center;
            background: #ffffff;
            li {
                display: inline-block;
                padding: 0 5px;
                @include font(16);
                button{
                    text-indent: -99999px;
                    @include reset(button);
                    width: 10px;
                    height: 10px;           
                    border-radius: 50%;  
                    transition: all 0.3s;   
                    background: $grey4;

                }
                &.slick-active{
                    button {
                        background: $grey2;
                    }
                }
            }
        }
    }
    .left,
    .banner-right {
        padding: 3%;
        @include breakpoint(large, 326) {
            padding: 6% 5% 7.5%;
        }
    }
    .left {
        flex: 3;
        background: $grey6;
        margin-right: 20px;
        @include breakpoint(large, 0) {
            font-size: 75%;
        }
        @include breakpoint(medium, -1) {
            padding-bottom: 6%;
            flex: 1;
        }
        @include breakpoint(custom, 500) {
            font-size: 60%; 
        }
        h4 {
            margin: 0;
            line-height: 1.3em;
            @include breakpoint(large, 0) {
                @include h4_p_font_large_screen();
            }
            @include breakpoint(custom, 500) {
                @include h4_p_font_500px();
            }
        }
        p {
            font-size: 15px;
        }
        .titleLink {
            color: $grey2;
            text-decoration: none;
            :hover {
                text-decoration: underline;
            }
        }
        .featured-type {
            font-weight: bold !important;
            font-size: 16px;
        }
        .time-est {
            padding-top: 10px;
        }
    }
    .carousel-img-container {
        float: right;
        flex: 2;
        margin: auto;
        padding: 0px;
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        overflow: hidden;
        .carousel-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.8s;
        }
        a:hover {
            .carousel-image {
                transform: scale(1.1);
            }
        }
        @media screen and (max-width: 1240px) {
            flex: 3;
        }
    }
    .card-container {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        transition: all 200ms ease-in-out;
    }
    .mobileLink {
        color: $grey2;
        text-decoration: none;
        width: 100%;
    }
    .mobile-card {
        align-self: flex-start;
        position: relative;
        width: 100%;
        transition: all 300ms ease-in-out;

        .mobile-card-img {
            width: 100%;
            height: 150px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            .mobile-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .mobile-card-info {
            margin-left: 10px;
            .mobile-article-type {
                font-weight: bold !important;
                font-size: 14px;        
                line-height: 0;
                @media screen and (max-width: 450px) {
                    font-size: 0.9rem;
                }
            }
            .mobile-card-title {
                color: $grey2;
                text-decoration: none;
                font-size: 16px;
                line-height: 1.875rem;
                @media screen and (max-width: 800px) {
                    font-size: 1rem;
                    line-height: 1.25rem;
                }
                @media screen and (max-width: 450px) {
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                }
            }
        }
    }
}
// case.details styles
.case-details {
    .container-case-details {
        @include clearfix();
        h5 {
            margin: 1em 0 0.5em;
        }
        padding-bottom: 3em;
        display: flex;
        align-items: flex-start;
        @include breakpoint(medium) {
            margin: 2em 0 0 0;
            display: block;
        }
    }
    .sideblock {
        float: right;
        background: #24242E;
        min-width: 250px;
        min-height: 250px;
        max-width: 520px;
        margin: 5em 0 1em 4em;
        color:white;
        @include breakpoint(large) {
            max-width: 45%;
            margin: 4em 0 2em 2em;
        }
        @include breakpoint(medium) {
            margin: 4em 0 0 0;
            float: unset;
            min-width: unset;
            flex: none;
            max-width: unset;
            width: 100%; 
        }
        .info, .contact{
            font-size: 80%;
            padding: 4em 3em;
            @include breakpoint(medium, 52) {
                padding: 3em 2em;
            }
        }
        a {
            color:white;
            transition: all 0.3s;
            @include hover {
                color:$ey_yellow
            }
        }
        .contact{
            background: $grey2;

            h3 {
                margin: 0 0 0.5em;
            }
            li {
                padding: 0.1em;
            }
        }
        ul {
            @include reset(ul);
            li {
                @include font(20);
                padding: 0.8em 0;
            }
            span {
                display: inline-block;
                white-space: 100%;

                &:last-child {
                    display: block;
                }
            }
        }
    }
    
    .textblock {
        padding-top: 3em;
        padding-bottom: 0;
        flex: 1;
        h1 {
            @include breakpoint(large) {
                font-size: 40px;
            }
            @include breakpoint(medium) {
                font-size: 34px;
            }
        }
        .inner {
            padding: 1.5em 1em;
            .link {
                padding: 0.75em 3em;
            }
            ul {
                .items{
                    display: flex;
                    flex-direction: row;
                    @include hover {
                        background-color: #F0F0F0;
                        transition: transform 0.25s ease-out;
                    }
                    .file-icon {
                        margin: auto;
                        flex: 1;
                        width: 20px;
                        height: 20px;
                    }
                    a {
                        flex: 8;
                        border-left: 0;

                        &:after {
                            content: $icon-download;
                            position: absolute;
                            border: 2px;
                            border-color: #101010;
                            border-radius: 100;
                            font-family: icons;
                            right: 3em;
                            top: 50%;
                            margin-top: -0.5em;
                            transition: all 0.3s;
                            @include breakpoint(medium, -1) {
                                right: 0;
                            }
                        }
                        
                        .documentTitle {
                            margin: 5px;
                            @include hover {
                                color: blue;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .more-info-cta {
        margin: 2em auto 1em;
        display: block;
        @include font(20);
        padding: 16px 2em;
        @include breakpoint(medium, -1) {
            margin: 0 auto 0;
        }
    }
}

// textblock styles
.textblock {
    padding-top: 4em;
    padding-bottom: 3em;
    h1 {
        margin: 0 0 0em;
        + p {
            @include font(24);
            margin: 1em 0;
        }
    }

    @include breakpoint(medium, -1) {
        padding-top: 4em;
    }
}

// login styles
.login {
    position: fixed;
    top: 100px;
    width: 920px;
    left: 50%;
    margin-left: -460px;
    box-sizing: border-box;
    padding: 4em;
    z-index: 3000;
    background: white;
    @include clearfix();
    display: none;
    &.overlay-active {
        display: block;
    }

    .close {
        position: absolute;
        top: 2em;
        right: 2em;
        font-weight: bold;
        font-size: 1.3em;
        cursor: pointer;
        @include reset(button);
        i {
            float: right;
            margin: 4px 0 0 5px;
            font-size: 1.2em;
        }
    }

    .error {
        background: red;
        color: white;
        font-weight: bold;
        border-radius: 8px;
        overflow: hidden;

        max-height: 0px;
        padding: 0;
        margin: 0;
        opacity: 0;
        transition: all 0.3s;

        &.display {
            padding: 1em;
            margin: 1em 0;
            opacity: 1;
            max-height: 100px;
        }
    }

    .title {
        text-align: center;
        font-weight: bold;
        margin: 0 0 1.2em;
    }
    p {
        @include font(20);
    }

    form {
        .item {
            position: relative;
            // padding: 1em 0 0;
            // @include font(20);
            // font-weight: bold;
            label,
            input {
                // cursor: pointer;
                width: 100%;
                @include font(20);
                padding: 1.3em 1em;
                box-sizing: border-box;
                margin-bottom: -1px;
                border: 1px solid #979797;
                &.email {
                    border-radius: 8px 8px 0 0;
                }
                &.password {
                    border-radius: 0 0 8px 8px;
                }
            }
            label {
                display: none;
            }
        }
        button {
            // float: right;
            // @include font(20);
            // font-weight: bold;
        }
        .show {
            @include reset(button);
            text-decoration: underline;
            position: absolute;
            right: 1em;
            top: 50%;
            margin-top: -0.5em;
            cursor: pointer;
        }
        .action {
            padding: 2em 0;
            button {
                display: block;
                text-align: center;
                width: 100%;
                @include font(20);
                padding: 1.3em 1em;
                font-weight: bold;
                background: #2e2e38;
                color: $ey_yellow;
                border-radius: 8px;
            }
        }
    }
    .forget,
    .info {
        color: $grey2;
        @include font(18);
        font-weight: bold;
        padding: 1em 0;
        a {
            color: $grey2;
        }
    }
}

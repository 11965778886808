// pagination styles
.pagination {
    text-align: center;
    padding: calc(min(4em, 7.5vw)) 0;
    > ul {
        @include reset(ul);
        width: 100%;
    }
    li {
        display: inline-block;
        button {
            @include reset(button);
            padding: 0.5em 0.7em;
            margin: 0 0.3em;
            @include font(14);
            i {
                font-size: 0.8em;
                margin: 0 0.5em;
            }
            cursor: pointer;

        }
    }
}

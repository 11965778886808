// article.details styles
.article-details {
    h1 {
        font-size: 44px;
    }
    h2 {
        font-size: 36px;
    }
    h3 {
        font-size: 24px;
        line-height: 2rem; 
        @include breakpoint(large, 0) {
            font-size: 20px;
            line-height: 1.75rem;
        }
    }
    h4 {
        font-size: 24px;
    }
    $ease: background .15s ease;

    #article-title {
        padding: 0px;
        font-weight: bold !important;
    }
    #subtitle {
        font-weight: bold !important;
    }

    .progress {
        position: fixed;
        left: 0;
        width: 100%;
        height: 10px;
        background: #1a1a24;
        z-index: 1;
        
        .bar {
            height: 100%;
            width: 10%;
            background: $ey_yellow;
            transition: $ease;
        }
    }
    @media print {
        .print {
            display: block;
        }
        .noprint {
            display: none;
        }
        .banner-with-text {
            -webkit-print-color-adjust: exact;
        }
    }
    .banner-with-text {
        background-size: cover;
        background-position: center;
        #banner-image {
            height: 25em;
        }
        .title {
            color: white;
            margin: 0;
            padding: 6em 0 3em;
            max-width: 1200px;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            @include breakpoint(small, 0) {
                font-size: 2rem;
                padding: 4em 0 2em;
            }
        }
    }
    .margin {
        margin-left: 7vw;
        margin-right: 7vw;
    }
    .article-info-container {
        padding: 0 68px;
    }
    .container-article-details {
        @include clearfix();
        .clock-icon {
            width: 20px;
            margin-right: 6px;
        }
        .icon-calendar {
            width: 20px;
            margin: auto;
            margin-left: 5px;
            padding-bottom: 1px;
        }
        .textblock {
            padding-top: 0em;
            overflow: visible;
            > h3:first-of-type {
                margin: 0 0 1em;
            }
            blockquote {
                background: #f9f9f9;
                border-left: 10px solid #ccc;
                margin: 1.5em 10px;
                padding: 0.5em 10px;
                width: fit-content;
              }
              blockquote:before {
                color: #ccc;
                content: open-quote;
                font-size: 4em;
                line-height: 0.1em;
                margin-right: 0.25em;
                vertical-align: -0.4em;
              }
              blockquote:after {
                color: #ccc;
                content: close-quote;
                font-size: 4em;
                line-height: 0.1em;
                margin-left: 0.25em;
                vertical-align: -0.4em;
              }
              blockquote p {
                display: inline;
              }
        }

        /* Accordion styles */

        .accordion-title{
            cursor:pointer;
          }
          .accordion-title::after{
            content:"+";
            float:right;
            margin-right:40px;
          }
          .accordion-title-open::after{
            content:"-";
            float:right;
            margin-right:40px;
          }
          .accordion-holder{
            margin-top:20px;
            margin-bottom:20px;
            padding-left: 40px;
            border-top: solid 1px #DFDFDF;
            border-bottom: solid 2px #DFDFDF;
            border-left: solid 4px #389ACB;
          }
          .accordion-holder p { padding-bottom:20px; }
          .accordion-content-holder{    
            height:0px;
            overflow:hidden;
          }
          .accordion-content-holder.active{    
            height: auto;
          }
          /* End Accordion styles */
    }
    .horizontal {
        display: flex;
        .reading-time {
            flex: 1;
            margin: auto;
        }
        .date {
            flex: 1;
            margin: auto;
            margin-left: 5px;
        }
        .share {
            flex: 4;
            text-align: right;
            margin: auto;
            margin-right: 5px;
            img {
                width: 30px;
            }
            @media screen and (max-width: 1024px) {
                flex: 2;
            }
        }
    }
    .sideblock {
        float: right;
        min-width: 250px;
        min-height: 250px;
        max-width: 520px;
        margin: 0.4em 0 1em 4em;
        @include breakpoint(medium, 172) {
            max-width: 45%;
            margin: 0em 0 2em 2em;
        }
        @include breakpoint(medium, -1) {
            min-width: unset;
            max-width: unset;
            width: 100%;
        }
        .authors {
            padding-bottom: 1em;
            .title {
                display: inline-block;
                padding-bottom: 0.5em;
            }
            .author {
                padding: 0.5em 0;
                span {
                    display: block;
                }
                .avatar {
                    float: left;
                    margin-right: 15px;
                    width: 45px;
                    border-radius: 50%;
                }
                .name {
                    font-weight: bold;
                    margin: 0 0 0.3em;
                }
                .creds {
                    @include font(14);
                }
            }
        }

        .reading-time {
            padding: 1em 0 0.2em;
            @include font(14);
        }
        .date {
            padding: 0.2em 0 1em;
            @include font(14);
        }

        .tags {
            max-width: 60%;
            @include reset(ul);
            padding: 1em 0;

            a {
                display: inline-block;
                padding: 0.3em 1em !important;
                border-radius: 3px;
                border: 1px solid $grey2 !important;
                color: $grey2;
                text-decoration: none;
                margin: 0.3em 0 0;
                &:last-child {
                    margin-bottom: 0.3em;
                }
            }
        }
        .share {
            padding: 0.5em 0 1em;
            a {
                display: inline-block;
                margin-right: 1em;
                cursor: pointer;
            }
        }
    }
    .copy-popup {
        display: none;
        background-color: $grey2;
        color: white;
        position: fixed;
        top: 40%;
        width: auto;
        left: 40%;
        box-sizing: border-box;
        padding: 1em 2em;
        z-index: 3000;
        text-align: center;

        @include clearfix();

        @include breakpoint(large, 0) {
            width: 70%;
            margin-left: 0;
            left: 15%;
            font-size: 70%;
        }
        @include breakpoint(medium, -1) {
            top: 40%;
        }
        @include breakpoint(small, 20) {
            font-size: 50%;
            padding: 3em;
        }
        p {
            @include font(20);
        }
    }
    .share-popup {
        display: none;
        background-color: white;
        position: fixed;
        top: 40%;
        width: auto;
        left: 40%;
        box-sizing: border-box;
        padding: 1em 2em;
        z-index: 3000;
        text-align: center;
        @include clearfix();

        @include breakpoint(large, 0) {
            width: 70%;
            margin-left: 0;
            left: 15%;
        }
        @include breakpoint(medium, -1) {
            top: 40%;
        }
        @include breakpoint(small, 20) {
            padding: 3em;
        }

        .share {
            padding: 0.5em 0 1em;
            a {
                display: inline-block;
                margin-right: 1em;
                cursor: pointer;
            }
        }

        p {
            font-weight: bold;
            @include font(20);
        }

        a.close {
            font-weight: bold;
            @include font(18);
            position: absolute;
            top: 8%;
            right: 8%;
            cursor: pointer;
            padding: 5px;
        }
    }
}

// filters styles
.filters {
    padding: 30px 0;
    box-sizing: border-box;

    // @include breakpoint(small) {
    //     display: none;
    //     position: fixed;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     overflow-x: visible;
    //     overflow-y: auto;
    //     height: 100%;
    //     left: 100px;

    //     box-sizing: border-box;
    //     z-index: 2000;
    //     background: white;
    //     box-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.1);
    //     box-sizing: border-box;
    //     padding: 1em 1.5em;
    //     padding-bottom: 3em;
    // }

    @include breakpoint(custom, 450) {
        left: 70px;
    }

    button.close-filter {
        position: fixed;
        top: 0;
        left: 40px;
        border: 0;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: $ey_yellow;
        color: $grey1;
        display: none;
        @include font(30);
        @include breakpoint(custom, 450) {
            left: 10px;
        }

        @include breakpoint(small) {
            display: block;
        }
        span {
            position: absolute;
            width: 0em;
            height: 0em;
            overflow: hidden;
            opacity: 0;
        }
    }

    button.show-filter-results {
        position: absolute;
        bottom: 1.5em;
        border: 0;
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: $ey_yellow;
        font-weight: bold;
        display: none;
        color: black;
        @include font(20);

        @include breakpoint(small) {
            display: block;
        }
    }

    &.overlay-active-filters {
        display: block;
    }

    .filters-inner-wrapper {
        position: relative;
        height: 100%;
        box-sizing: border-box;
    }

    .boxed & {
        padding: 20px 30px;
        background: white;
        margin-top: 30px;
        margin-bottom: 30px;
        border-radius: 3px;
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }

    .top-corner & {
        padding: 10px 0 0;
    }

    ul {
        @include reset(ul);
        @include clearfix();
    }

    > .heading {
        display: none;
        @include breakpoint(small) {
            display: block;
            font-size: 1.7em !important;
            margin: 0 0 0.5em;
        }
    }
    .filters-inner-wrapper {
        > ul {
            @include reset(ul);
            @include clearfix();
            > li {
                float: left;
                padding: 20px 3px;
                margin-right: 40px;
                &:last-child {
                    margin-right: 0;
                }
                // @include breakpoint(small) {
                //     float: none;
                //     clear: both;
                //     display: block;
                //     padding: 1em 0;
                //     margin: 0;
                // }
                button {
                    @include reset(button);
                    @include fontstyle(secondary);
                    @include font(14);
                    // float: left;
                    i {
                        font-size: 0.8em;
                        margin-left: 3px;
                    }
                    cursor: pointer;
                }
                .widget {
                    float: left;
                    &.dark {
                        background: $grey3;
                        padding: 0.6em 1.5em;
                        margin: -0.2em 0 0;
                        @include breakpoint(small) {
                            background: transparent;
                            padding: 0;
                            width: 100%;
                        }
                        button {
                            &.trigger {
                                color: $ey_yellow;
                                @include breakpoint(small) {
                                    color: black;
                                }
                            }
                            &.facet {
                            }
                        }
                    }
                    &.light {
                        button {
                            &.facet {
                                border: 1px solid #cdcdcd;
                                background: transparent;
                                padding: 0.5em 0.8em;
                                margin-top: -0.3em;
                                margin-bottom: -0.3em;
                            }
                        }
                    }
                    button {
                        &.trigger {
                            // margin-right: 1em;
                            + .facet {
                                margin-left: 1em;
                            }
                        }
                        &.facet {
                            font-size: 0.7em;
                            padding: 0.3em 0.5em;
                            background: #efefef;
                            border-radius: 3px;
                            margin-right: 0.3em;
                            margin-top: -0px;
                            &:first-child {
                            }
                            @include breakpoint(small) {
                                margin-top: 0.3em;
                            }
                        }

                        .colorcode {
                            display: inline-block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background: $grey1;
                            margin-right: 5px;
                            &.yellow {
                                background-color: $ey_yellow;
                            }
                        }
                    }
                    .drop-down-box {
                        input {
                            width: 100%;
                            box-sizing: border-box;
                            padding: 10px 0;
                            border: 0;
                            // border-bottom: 1px solid #cdcdcd;
                            // border-radius: 5px;
                            outline: 0;
                            box-shadow: none;
                        }
                        h4 {
                            // @include font(12);
                            // margin: 10px 0 10px;
                            display: inline-block;
                            @include font(12);
                            margin: 0.3em 0 0.3em;
                            font-weight: bold;
                            text-transform: uppercase;
                        }

                        ul {
                            @include font(14);
                            li {
                                width: 100%;
                                a {
                                    @include clearfix();
                                    padding: 5px 0;
                                    width: 100%;
                                    color: black;
                                    display: block;
                                    text-decoration: none;
                                    @include hover() {
                                        text-decoration: underline;
                                    }
                                    .name {
                                        float: left;
                                    }
                                    .value {
                                        opacity: 0.7;
                                        float: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.filters-display-button {
    margin: 25px 0;
    padding: 0.7em 1em;
    border: 1px solid #3d3e42;
    background: transparent;
    border-radius: 3px;
    transition: all 0.3s;
    font-family: "Montserrat", sans-serif;
    display: none;
    float: left;
    @include breakpoint(small) {
        display: block;
        @include font(14);
    }
    i {
        font-size: 0.8em;
        float: left;
        margin: 0.45em 3px 0 0;
    }
    @include hover() {
        background: #3d3e42;
        color: white;
    }
    &.overlay-active {
        background: #3d3e42;
        color: white;
    }
}

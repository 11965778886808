// content-overlay styles

.content-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    cursor: pointer;
    height: 100vh;
    background: rgba(255,255,255,0.6);
    z-index: 1000;
    display: none;
    transition: all 0.3s;
    &.overlay-active, &.overlay-active-filters {
        display: block;
    }
    @include hover() {
        // background: rgba(0, 0, 0, 0.2);
    }
}

// search.list styles
.search-list {
    background: #eaeaf2;
    min-height: 100px;

    &.articles {
        background: white;
    }

    .filters {
        .filters-inner-wrapper {
            @include clearfix();
            > ul {
                float: right;
                @include breakpoint(small, 0) {
                    font-size: 80%;
                }
                @include breakpoint(custom, 400) {
                    font-size: 55%;
                }
                > li {
                    float: left;
                    padding: 5px 3px;
                    @include breakpoint(small, 0) {
                        margin-right: 2em;
                        &:last-child {
                            margin-right: inherit;
                        }
                    }
                    button {
                        @include fontstyle(ey);
                        @include font(16);
                        font-weight: bold;
                    }
                    &:last-child {
                        .drop-down-box {
                            right: 0;
                            left: auto;
                        }
                        @media (max-width: 640) {
                            .drop-down-box {
                                right: auto;
                            }
                        }
                    }
                    
                }
            }
        }
    }

    .list {
        min-height: 100px;
        background: white;
        @include breakpoint(custom, 500) {
            font-size: 80%;
        }
        .inner {
            margin: 0 auto;
            padding: 5em 6em;
            @include breakpoint(medium, -1) {
                padding: 3em 2em;
            }
        }
        ul {
            @include reset(ul);
            li {
                @include clearfix();
                border-bottom: 1px solid $grey4;
                &:first-child {
                    border-top: 1px solid $grey4;
                }
            }
            a {
                color: $grey2;
                text-decoration: none;
                display: block;
                padding: 1.5em 3em;
                box-sizing: border-box;
                padding-right: 6em;
                border-left: 5px solid transparent;
                position: relative;
                transition: all 0.3s;
                @include breakpoint(medium, -1) {
                    padding: 1.5em 2em;
                }
                @include breakpoint(custom, 400) {
                    padding: 1.5em 1.5em;
                    .title {
                        font-size: 1rem;
                    }
                }
                &:after {
                    content: $icon-chevron-right;
                    position: absolute;
                    font-family: icons;
                    right: 3em;
                    top: 50%;
                    margin-top: -0.5em;
                    transition: all 0.3s;
                    @include breakpoint(medium, -1) {
                        right: 0;
                    }
                }
                @include hover {
                    border-left-color: $ey_yellow;
                    &::after {
                        right: 2em;
                        @include breakpoint(medium, -1) {
                            right: 0;
                        }
                    }
                }
            }
            .no-results {
                text-align: center;
                color: $grey2;
                font-size: 20px;
            }
            .title {
                margin: 0 0 0.2em;
            }
            .img-container {
                float: right;
                width: 370px;
                height: 255px;
                overflow: hidden;
                position: relative;
                margin: 0 0 0 2em;
                .image-link {
                    width: 100%;
                    height: 100%;
                }
                .image {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.3s;
                    @include hover {
                        transform: scale(1.15);
                    }
                }
            }
            .description {
                padding: 1.5em 0;
            }
            .info {
                color: $grey4;
                span {
                    display: inline-block;
                }
                .info1 {
                    padding-right: 0.5em;
                    margin-right: 0.5em;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        width: 1px;
                        top: 3px;
                        bottom: 3px;
                        border-right: 1px solid $grey4;
                    }
                }
            }
            .tags {
                padding-top: 1.5em;
                max-width: 60%;
                a {
                    display: inline-block;
                    padding: 0.3em 1em !important;
                    border-radius: 3px;
                    border: 1px solid $grey2 !important;
                    margin-right: 1em;
                }
            }
        }
    }
}

.articles {
    .list {
        .inner{
            padding-top: 0em;
            padding-bottom: 0em;
            

            li {
                padding: 4em 0;
                &:first-child{
                    padding-top: 2em !important;
                    border-top: 0 !important;
                }
                &:last-child{
                    border-bottom: 0 !important;
                    padding-bottom: 2em !important;
                }
            }
            a {
                padding: 0 !important;
                border: 0 !important;
                &:after {
                    display: none;
                }
            }
            .title {
                @include font(26);
                font-weight: bold;
                transition: all 0.3s;
                @include hover {
                    text-decoration: underline;
                }

                @include breakpoint(large, 0) {
                    margin-top: 1em;
                }
            }
            .info {
                color: $grey2 !important;
                .info2 {
                    font-weight: bold;
                }
            }

            .article-link {
                @include breakpoint(large, 0) {
                    width: 100%;
                    margin: 0;
                    float: none;
                }
            }

            .image {
                @include breakpoint(large, 0) {
                    height: 0;
                    padding-bottom: 68% !important;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        .items {
            display: flex;
            flex-direction: column;
        }
        .img-container {
            margin: auto !important;
            padding-bottom: 10px;
            width: 100% !important;
        }
    }
}

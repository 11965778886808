@mixin fontstyle($style) {
    @if $style == primary {
        // Nothing
    } @else if $style == secondary {
        font-family: "Montserrat", sans-serif;
    } @else if $style == ey {
        font-family: 'Noto Sans', sans-serif;
    } @else {
        // Nothing
    }
}
